import React from 'react';
import styled, { css } from 'styled-components';
import Link from 'components/Link';

import ScreenReaderText from 'components/screen-reader-text';
import {
	ContentToRichText,
	getEmailLink,
	getUrl,
	convertBytesToMb,
	getComponentSettings,
} from 'libs/content';
import { AnchorLink } from 'components/Link';
import Heading from 'libs/heading';

const Wrapper = styled.div``;
const VideoWrap = styled.video`
	width: 100% !important;
	height: auto !important;
	border-radius: ${p => p.theme.utils.borderRadius};
`;

const DocumentTitle = styled.span`
	font-size: 16px;
	margin: 0;
`;

const TextBoxWrap = styled.div`
	background: ${p => p.theme.colors.grey300};
	border-radius: ${p => p.theme.utils.borderRadius};
	padding: 30px !important;
	font-weight: normal;
	max-width: 475px;
	width: 100%;
	float: right;
	margin-bottom: 30px;
	margin-top: 30px;
	margin-left: 20px;
	${p =>
		p.theme.media.smallOnly(css`
			margin: 30px 0 0;
			float: none;
			width: 100%;
			max-width: 100%;
		`)}

	@media (max-width: 1230px) and (min-width: 1021px) {
		margin-left: -76px;
		transform: translateX(96px);
	}
	@media (min-width: 1231px) {
		margin-left: -176px;
		transform: translateX(196px);
	}
	h3 {
		margin: 0 0 15px 0 !important;
		${p =>
			p.theme.media.XSmallOnly(css`
				margin: 0 0 10px 0 !important;
			`)}
	}
	p {
		&:last-of-type {
			margin: 0;
		}
	}
`;

export function TextLink({ url = '', text = '' }) {
	let link = url;
	let target = '_self';
	let rel = '';
	let parentPath = url.split('/').slice(-2).join('/');
	let phoneOrMailLink =
		url.includes('tel:') || url.includes('mailto:') || url.includes('@');
	let internal = true;

	// If Url does start with http or www
	if (
		url.includes('http') ||
		url.includes('www') ||
		url.includes('ctfassets')
	) {
		if (
			(!url.includes('localhost') &&
				!url.includes('//nte.no') &&
				!url.includes('//www.nte.no')) ||
			url.includes('nte.no/filer')
		) {
			internal = false;
			target = '_blank';
			rel = 'noopener noreferrer';
		}
	} else {
		// If local link then detect what parent path in url is and use function with correct param
		if (phoneOrMailLink) {
			internal = false;
			if (url.includes('@') && !url.includes('mailto:'))
				link = getEmailLink(url);
		} else {
			switch (parentPath) {
				case 'blogg':
					link = getUrl(url, 'blogg');
					break;
				default:
					link = getUrl(url);
					break;
			}
		}
	}

	if (internal) {
		link = link
			.replace('https://www.nte.no', '')
			.replace('https://nte.no', '')
			.replace('http://localhost:8000', '');
		return (
			<Link
				to={link}
				title={text?.length > 0 ? text[0]?.props?.children : text}>
				{text}
			</Link>
		);
	}

	return (
		<AnchorLink
			href={link}
			title={text?.length > 0 ? text[0]?.props?.children : text}
			target={target}
			rel={rel}>
			{text}
		</AnchorLink>
	);
}

export function Video({ url, size, ariaLabel, description }) {
	const limit = 80;

	if (convertBytesToMb(size) > limit) {
		console.log(
			`Video file ${url} is ${convertBytesToMb(
				size
			)} MB. The limit of video files is ${limit} MB.`
		);
		return null;
	}

	return (
		<Wrapper>
			<VideoWrap
				width="1024"
				height="576"
				controls
				aria-label={ariaLabel || ''}>
				{url && (
					<source
						src={url}
						type={`video/${url?.replace(/^.*\./, '')}`}
					/>
				)}
				<p>
					Nettleseren din støtter ikke HTML5-video.{' '}
					<AnchorLink href={url} title="Se video via lenke">
						Klikk her for å se videoen via en lenke i stedet.
					</AnchorLink>
				</p>
			</VideoWrap>
			{description && <ScreenReaderText>{description}</ScreenReaderText>}
		</Wrapper>
	);
}

export function Document({
	url,
	title,
	contenttype,
	fileName,
	fileSize,
	fileExtension,
}) {
	return (
		<AnchorLink
			href={url}
			download={url}
			type={contenttype || 'unknown'}
			title={(title && `Last ned "${title}"`) || fileName || ''}
			target="_blank"
			rel="noreferrer">
			<DocumentTitle>
				<span>{title ? title : fileName ? fileName : ''}</span>
				{fileExtension && <abbr> (.{fileExtension})</abbr>}
				{fileSize && (
					<ScreenReaderText>
						{fileSize} <abbr title="Kilobyte">KB</abbr>
					</ScreenReaderText>
				)}
			</DocumentTitle>
		</AnchorLink>
	);
}

export function TextBox({ ...field }) {
	const componentSettings = getComponentSettings({
		settings: field?.settings,
	});
	if (!field) return <></>;

	return (
		<TextBoxWrap className="gray-box">
			{field?.title && componentSettings?.hidetitle !== 'true' && (
				<Heading level="h3">{field?.title}</Heading>
			)}
			{field?.content?.raw && <ContentToRichText data={field?.content} />}
		</TextBoxWrap>
	);
}
